/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import userActions from "../../../Redux/user/actions";
import invoiceActions from "../../../Redux/invoices/actions";
import invoicePdfActions from "../../../Redux/invoicesPdf/actions";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { colors } from '../../../helpers/appColors';
import _ from 'lodash';
import {showError} from "../../../helpers/notificationHelper";
import { Table, Button, Input, Select, Tooltip , Upload, Row, Col, Popconfirm, Form, Modal} from 'antd';
import { CheckOutlined, DeleteOutlined, CloseCircleOutlined, EyeInvisibleOutlined, EyeOutlined , PlusOutlined , UploadOutlined} from '@ant-design/icons';
import calculatorActions from "../../../Redux/calculator/actions";
import history from "../../../helpers/history";
import { IdNameCompany, shortenFileName } from '../../../helpers/utils';
import { FormInput } from '../../../helpers/forms';
import clientsActions from '../../../Redux/clients/actions';
import courrierLabels from "../../../helpers/courrierLabels";
import apiConfig from './../../../config/api.config';

import {v4 as uuidv4} from 'uuid';
import { FormAutocomplete } from '../../../helpers/forms';

const { Option } = Select;

const SelectActionOptions = ["Créer une relance", "create_reminder"];

class CustomerInvoices extends Component{

  state = {
    searchValue: null,
    selectedOption: SelectActionOptions,//"create_reminder",
    selectedRows: [],
    archivedVisible: false,
    disabledSubmit: true,
    companyName : "",
    companyId : null,
    index : 0,
    invoices : [
      {
        number: null,
        emittedAt:null,
        dueAt: null,
        totalWithTaxes: null,
        payments: [],
        disabled: false,
        factureName: '',
        factureUrl: ''
      }
    ],
  };

  formRef = React.createRef();
  componentDidMount(){
    this.props.fetchInvoices();
    this.props.fetchClients();
    this.formRef.current.setFieldsValue({companyId:null,companyName:this.state.companyName,invoices:this.state.invoices})
  }

  async componentDidUpdate(prevProps){
      const { invoicesPdf } = this.props;
      const { pdfUrl } = invoicesPdf;
      const { index } = this.state;
      const newFormValues = this.formRef.current.getFieldsValue();
    
      if (pdfUrl !== prevProps.invoicesPdf.pdfUrl) {
        newFormValues.invoices[index].factureUrl = pdfUrl;
        this.formRef.current.setFieldsValue(newFormValues);
        this.setState({ invoices: newFormValues.invoices });
      }

      const isModalVisible = this.props.isModalVisible;

      if (prevProps.isModalVisible !== isModalVisible && isModalVisible === false){
          this.onCancel();
      }
  }

  handleSubmitAction = () => {
    let selectedIds = [];
    for(let option of this.state.selectedRows){
      selectedIds.push(option.key);
    }

    if(this.state.selectedOption === 'archive'){
      this.props.bulkArchiveInvoice(selectedIds, true);
    }else{
      let invoicesToSend = [];
      let tempCompany;
      for(const id of selectedIds){
        const invoiceFound = _.find(this.props.invoices, {id});
        if(invoiceFound){
          if(tempCompany && tempCompany.id !== invoiceFound.company.id){
            showError('DIFFERENT_COMPANIES');
            return;
          }else {
            tempCompany = invoiceFound.company;
            invoiceFound.payments = [];
            invoicesToSend.push(invoiceFound);
          }
        }
      }
      this.props.updateDefaultFormValues({
        companyId:tempCompany.id,
        companyName:tempCompany.name,
        invoices: JSON.stringify(invoicesToSend),
        step:1,
        rateType:'none'
      });


      let selectedOptionUrl = "";
      switch (this.state.selectedOption) {
        case "Pénalités_retard":
          selectedOptionUrl = "/penalites";
          break;
        case "Amiable":
          selectedOptionUrl = "/amiable";
          break;
        case "Contentieux":
          selectedOptionUrl = "/contentieux";
          break;
        case "Judiciaire":
          selectedOptionUrl = "/judiciaire";
          break;
        default:
          selectedOptionUrl = "/main"; // Modifiez cela en conséquence
          break;
      }
  
      history.push(selectedOptionUrl);
    }
  };

  removeFacture = async (index,key) =>{
    // const invoices = this.props.invoices;
    // invoices[index].factureUrl=null;
    // invoices[index].factureName=null;
    // this.props.updateInvoices(invoices);
    console.log(index);
    this.props.removePDFRequest({id: key});
  } 

  handleUploadFile(event,key,number){ 
    const file = event.fileList[0];
    
     this.props.uploadPDFRequest({pdf:file, invoiceId:key ,number:number,isDashboard:true});
  }

  onFinish = (values) => {
    console.log("Here");
  }

  validateFormAndSubmit = () => {
    const values = this.formRef.current.getFieldsValue();

    const { companyName,invoices, companyId } = values;

    if(!companyName){
      return showError('MISSING_INPUT');
    }

    for(let invoice of invoices){
      if(!(invoice.number && invoice.emittedAt && invoice.dueAt && invoice.totalWithTaxes)){
        return showError('MISSING_INPUT');
      }
    }
    
    this.props.bulkAddInvoice({companyName, companyId , invoices});

  }

  handleAddInvoice = () => {
    const obj = {
      number: null,
      emittedAt: null,
      dueAt: null,
      totalWithTaxes: null,
      payments: [],
      disabled: false,
      factureName: '',
      factureUrl: ''
    };

    const newFormValues = this.formRef.current.getFieldsValue();
    newFormValues.invoices.push(obj);

    this.formRef.current.setFieldsValue(newFormValues);
    this.setState({
      invoices: newFormValues.invoices
    })
  }

  handleSelectCompany = (value,option) =>{
    const { companyName } = IdNameCompany(value);
    if (_.find(this.props.companies, {'companyName': companyName,'id': option.id})) {

      const newFormValues = this.formRef.current.getFieldsValue();
      newFormValues.companyId = option.id;
      newFormValues.companyName = companyName;

      this.formRef.current.setFieldsValue(newFormValues);
      this.setState({
        companyName: companyName,
        companyId : option.id
      });
    }
  }

  handleChangeCompany = (value) => {
    const { companyId, companyName } = this.formRef.current.getFieldsValue();
    if(companyId){
      if(!companyName || !_.find(this.props.myCompanies, {'name': companyName})){
        this.formRef.current.setFieldsValue({
          companyId: null
        });
      }
    }
  };

  resetCompanyName = () => {

    const obj = {
      companyId : null,
      companyName: null
    };

    this.formRef.current.setFieldsValue(obj);

    this.setState(obj);

  };

  onCancel = () => {

    const obj = {
      companyId : null,
      companyName: null,
      invoices: [
        {
          number: null,
          emittedAt: null,
          dueAt: null,
          totalWithTaxes: null,
          payments: [],
          disabled: false,
          factureName: '',
          factureUrl: ''
        }
      ]
    };

    this.formRef.current.setFieldsValue(obj);

    this.setState(obj);

    this.props.isModalOpen(false);

  };

  onSelectChange = (value) =>{
    this.setState({
      selectedOption: value
    });
    if(this.state.selectedRows.length>0){
      this.setState({
        disabledSubmit: false
      })
    }
  }

  render(){

    const companies = this.props.companies ? this.props.companies : [];

    const companyOptions = [];

    companies.map((el) => {
      companyOptions.push({
        label: `${el.companyName} - ${el.city ? `${el.city}`:``}`,
        value: `${el.id}-${el.companyName}`,
        id: el.id
      })
    });

    const columns = [
      {
        title: 'Facture',
        render: (text, record, index) => {
          const factureContent = record?.factureUrl ? (
            <>
              <Col span={19}  className='link'>
                <a href={`${apiConfig.baseUrl}/facture/${this.props.userId}/${record?.factureUrl}`} target='_blank' className='link-file'>{record?.factureName}</a>
              </Col>
              <Col span={5}>
                <Popconfirm
                  title="Voulez-vous vraiment supprimer cette facture"
                  onConfirm={()=> this.removeFacture(record.index,record.key)}
                  onCancel={() => false}
                  okText="Oui"
                  cancelText="Annuler"
                >
                    <Button key={record.uuid} type='default' shape='circle' style={{ borderRadius: '50%' }} icon={<DeleteOutlined />} />
                </Popconfirm>
              </Col>
            </>
          ) : (
            <Col className='upload-file'>
              <Upload key={record.uuid} accept={'.pdf'} fileList={[]} maxCount={1} onChange={(event)=>{this.handleUploadFile(event,record.key,record.number)}}>
                <Button>Parcourir... </Button>
              </Upload>
            </Col>
          );
        
          return (
            <Row gutter={[8, 8]} key={record.uuid}>
              {factureContent}
            </Row>
          );
        },
        
        showSorterTooltip: false
      },
      {
        title: 'Numéro',
        dataIndex: 'number',
        sorter: (a,b) => a.number < b.number ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Nom / Raison sociale',
        dataIndex: 'companyName',
        sorter: (a,b) => a.companyName < b.companyName ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Date',
        dataIndex: 'emittedAt',
        render: (text, record, index) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
        sorter: (a,b) => a.emittedAt < b.emittedAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Echéance',
        dataIndex: 'dueAt',
        render: (text, record, index) => <span>{moment(record.dueAt).format('DD/MM/YYYY')}</span>,
        sorter: (a,b) => a.dueAt < b.dueAt ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Retard',
        render: (text, record, index) => {
          let color = 'black';
          if((moment({hours: 0}).diff(record.dueAt, 'days') > -4) && (moment({hours: 0}).diff(record.dueAt, 'days') < 4)){
            color = colors.primaryGreen;
          }else if((moment({hours: 0}).diff(record.dueAt, 'days') >= 4 && (moment({hours: 0}).diff(record.dueAt, 'days') < 30))){
            color = colors.primaryOrange;
          }else if((moment({hours: 0}).diff(record.dueAt, 'days') >= 30 )){
            color = colors.primaryRed;
          }else{
            color = 'black';
          }
          return (
            <span style={{color: color}}>
              {moment({hours: 0}).diff(record.dueAt, 'days')} j
            </span>
          )
        },
        sorter: (a,b) => moment({hours: 0}).diff(a.dueAt, 'days') < moment({hours: 0}).diff(b.dueAt, 'days') ? -1:1,
        showSorterTooltip: false
      },
      {
        title: 'Montant',
        dataIndex: 'mainBalance',
        render: value => <span>
          <NumberFormat
            displayType={'text'}
            value={value}
            decimalSeparator={','}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={' '}
            suffix={'€'}
          />
        </span>,
        sorter: (a,b) => a.mainBalance < b.mainBalance ? -1:1,
        showSorterTooltip: false
      },
      {
        title: (
          <span>
            Dernière relance
          </span>
        ),
        dataIndex: 'lastAction',
        render: (text, record, index) => {
          if(record.lastAction){
            return (
              <div style={{textAlign: 'center'}}>
                <Button
                  type='default'
                  href={record.lastActionUrl}
                  size='small'
                  target={'_blank'}
                >
                  {courrierLabels[text]}
                </Button>
                <br/> le {moment(record.lastActionAt).format('DD/MM/YYYY')}
              </div>
            )
          }
        },
      },
      {
        title: (
          <span>
            Archivées
          </span>
        ),
        render: (text, record, index) => (
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            {
              record.archived ? (
                <Tooltip placement="top" title={`Restaurer cette facture`}>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() => this.props.bulkArchiveInvoice([record.key], false)}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
              ):(
                <Tooltip placement="top" title={`Archiver cette facture`}>
                  <Button
                    type='default'
                    size='small'
                    onClick={() => this.props.bulkArchiveInvoice([record.key], true)}
                  >
                    <EyeInvisibleOutlined />
                  </Button>
                </Tooltip>
              )
            }
          </div>
        ),
      },
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows
        })
        if (selectedRows.length > 0 ) {
          if (this.state.selectedOption[1] === "create_reminder") {
            this.setState({
              disabledSubmit: true,
            });
          }else{
            this.setState({
              disabledSubmit: false
            })}
        } else {
          if (this.state.selectedOption[1] === "create_reminder") {
            this.setState({
              disabledSubmit: true,
            });
          }else{
            this.setState({
              disabledSubmit: true
            })}
        }

      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    let data = [];

    this.props.invoices.map((invoice, idx) => {
      data.push({
        index: idx,
        uuid: uuidv4(),
        key: invoice.id,
        archived: invoice.archived,
        importedAt: invoice.createdAt,
        companyName: invoice.company.name,
        emittedAt: invoice.emittedAt,
        dueAt: invoice.dueAt,
        mainBalance: invoice.totalWithTaxes,
        number: invoice.number,
        lastAction: invoice.order[0] ? invoice.order[0].documentType : null,
        lastActionAt: invoice.order[0] ? invoice.order[0].createdAt :  null,
        lastActionUrl: invoice.order[0] ? invoice.order[0].documentUrl : null,
        factureUrl: invoice.factureUrl ? invoice.factureUrl : null,
        factureName: invoice.factureName ? invoice.factureName : null
      })
    });

    data = data.filter(el => {
      const formattedLastAction = courrierLabels[el.lastAction] ? courrierLabels[el.lastAction] : '';
      if(
        this.state.searchValue
        && el.companyName.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && el.mainBalance.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && el.number.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
        && formattedLastAction.toString().toUpperCase().search(this.state.searchValue.toUpperCase()) === -1
      ) return false;
      return true;
    });

    data = data.filter(el => {
      if(!this.state.archivedVisible && el.archived) {
        return false;
      }else if(this.state.archivedVisible && !el.archived){
        return false;
      }
      return true;
    });

    const InvoiceForm = ({field,index,remove,fields}) => {
        const handleRemove = (fieldName,index) => {
          if(fields.length === 1){
            const resetedInvoices = [{
              id:null,
              number: null,
              emittedAt: null,
              dueAt: null,
              totalWithTaxes: null,
              payments: [],
              disabled: false
            }];
  
            const newFormValues = this.formRef.current.getFieldsValue();
            newFormValues.invoices = resetedInvoices;
            this.formRef.current.setFieldsValue(newFormValues);
  
            this.setState({
              invoices: resetedInvoices
            });
          }else{
            let newInvoicesList = [];
            this.state.invoices.map((el, idx) => {
              if(index !== idx){
                newInvoicesList.push(el);
              }
            });
            this.setState({
              invoices: newInvoicesList
            });
            remove(fieldName);
          }  
        }

        const handleUploadFile = (event,index) =>{
          
          const file = event.fileList[0];
        
          const newFormValues = this.formRef.current.getFieldsValue();
          newFormValues.invoices[index].factureName = file.name;
  
          this.formRef.current.setFieldsValue(newFormValues);
          this.setState({
            invoices: newFormValues.invoices,
            index: index
          })

           // SEND FILE
          this.props.uploadPDFRequest({pdf:file, invoiceId: newFormValues.invoices[index].id,number:newFormValues.invoices[index].number,isDashboard:false});
        }

        const handleRemoveFile = (event,index) =>{
          const path = this.state.invoices[index].factureUrl;

          const newFormValues = this.formRef.current.getFieldsValue();
          newFormValues.invoices[index].factureName = null;
          newFormValues.invoices[index].factureUrl = null;

          this.formRef.current.setFieldsValue(newFormValues);
          this.setState({
            invoices: newFormValues.invoices,
            index: index
          })
        }

        return (
          <>
            <Row gutter={16} key={field.key}>
              <Col span={6}>
                  <FormInput  preserve={true} name={[index, 'number']} label='N° de facture'  rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={5}>
                  <FormInput  preserve={true} name={[index, 'emittedAt']} label='Date de facture'  type='datePicker' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={5}>
                  <FormInput  preserve={true} name={[index, 'dueAt']} label={`Date d'échéance`} type='datePicker' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={6}>
                  <FormInput  preserve={true} name={[index, 'totalWithTaxes']} label='Montant (ttc)' type='currency' rules={[{ required: true, message: `Ce champ est obligatoire` }]}/>
              </Col>
              <Col span={2} style={{paddingTop: 30}}>
                    <Button type='default' size='small' shape='circle' onClick={()=>handleRemove(field.name,index)}><DeleteOutlined/></Button>
              </Col>
            </Row>
            <Row gutter={[8,16]}>
                <Col span={24}>
                  <div style={{marginBottom:10}}>
                    <div style={{display:'none'}}>
                      <FormInput  name={[index,'factureName']}  preserve={true} type='hidden'></FormInput>
                      <FormInput  name={[index,'factureUrl']}  preserve={true} type='hidden'></FormInput>
                    </div>
                    {!this.state.invoices[index].factureUrl && (
                    <>
                      <span className='label-upload' style={{fontWeight: 'bold', marginRight: 10 }}>
                        Facture 
                      </span>
                      <Upload key={uuidv4()} accept={'.pdf'}  onChange={(event)=>{ handleUploadFile (event,index) }} fileList={[]} maxCount={1}>
                          <Button className='upload-btn'  size='middle'>Parcourir...</Button>
                      </Upload>
                    </>

                    )}
                    {this.state.invoices[index].factureUrl && (
                      <div>
                        <a href={`${apiConfig.baseUrl}/facture/${this.props.userId}/${this.state.invoices[index].factureUrl}`} target='_blank' style={{color:'black',textDecoration:'underline',marginRight:10}}>{this.state.invoices[index].factureName}</a>
                        <Button style={{background:'transparent',color:'red',border:'none',textDecoration:'underline'}} onClick={(event)=>{ handleRemoveFile(event,index)} }>supprimer</Button>
                      </div>
                    )}
                  </div>
                </Col>
            </Row>
          </>
        )
    }

    const companyId = this.state.companyId;


    return (
      <>
        <Form ref={this.formRef} onFinish={this.onFinish} id='invoices'>
            <Form.Item name={'companyId'} hidden={true}/>
            <Modal visible={this.props.isModalVisible} title="Ajouter vos factures" footer={null} width={1000} onCancel={this.onCancel}>
              <Row style={{textAlign: 'center',alignItems: 'center'}} gutter={16}>
                <Col span={12}>
                  <strong>Nom / Raison sociale de votre débiteur <span style={{color: 'red'}}>*</span></strong>
                </Col>
                <Col span={9}>
                  <FormAutocomplete
                      disabled={companyId}
                      name={'companyName'}
                      options={companyOptions}
                      rules={[{ required: true, message: `Ce champ est obligatoire` },{max: 44, message: `Maximum 45 caractères`, warningOnly: true}]}
                      onSelect={this.handleSelectCompany}
                      normalize={value => (value || '').toUpperCase()}
                      onChange={this.handleChangeCompany}
                      maxLength={45}
                  >
                  </FormAutocomplete>
                </Col>
                <Col span={1}>
                    <CloseCircleOutlined
                      onClick={() => this.resetCompanyName()}
                      style={ companyId ? {fontSize: 18, marginBottom:5, color: colors.textGrey} : {display: 'none'}}/>
                </Col>

              </Row>

              <Row gutter={16}>
                  <Form.List name="invoices">
                      {
                        (fields,{ add, remove }) => (
                          <>
                            {
                              fields.map((field, index) => (
                                  <InvoiceForm key={field.key} field={field} index={index} remove={remove} fields={fields} />                 
                              ))
                            }
                            <Row gutter={16} style={{margin: 0}}>
                              <Col span={24}>
                                <Button
                                  type="default"
                                  style={{backgroundColor:colors.primaryBlue, border: 'none', color: 'white'}}
                                  size="small"
                                  onClick={this.handleAddInvoice}
                                >
                                    <PlusOutlined/> Ajouter
                                </Button>
                            </Col>
                          </Row>
                          </>
                        )
                      }
                  </Form.List>
              </Row>
              <Row style={{textAlign: 'center'}}>
                  <Col span={24}>
                      <Button
                       type="primary"
                       onClick={() => this.validateFormAndSubmit()}
                      >
                        Valider
                      </Button>
                  </Col>
              </Row>
            </Modal>
        </Form>
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <Select  value={this.state.selectedOption} placeholder="Créer une relance" style={{ width: 170 }} onChange={this.onSelectChange}>
                <Option value="Pénalités_retard">Pénalités de retard</Option>
                <Option value="Amiable">Amiable</Option>
                <Option value="Contentieux">Contentieux</Option>
                <Option value="Judiciaire">Judiciaire</Option>
              </Select>
              <Button type={'primary'} disabled={this.state.disabledSubmit} style={{marginLeft: 5}} onClick={this.handleSubmitAction} ><CheckOutlined /></Button>
            </div>
            <div>
              <Button type='primary' style={{marginRight: 5}} onClick={()=>this.props.isModalOpen(true)} ><PlusOutlined /> Ajouter</Button>
              {/*<Button type={'default'} style={{marginRight: 5}} ><DownloadOutlined /> Importer (.xls)</Button>*/}
              {<Button type={'default'} disabled={data.length > 0 ? false: true } style={{marginRight: 5}} onClick={()=>this.props.exportToExcel(this.state.archivedVisible)} ><UploadOutlined /> Exporter</Button>}
              {
                !this.state.archivedVisible ? (
                  <Button type={'default'} style={{marginRight: 5}} onClick={() => {this.setState({archivedVisible: true})}} ><EyeInvisibleOutlined/> Archivées</Button>
                ):(
                  <Button type={'primary'} style={{marginRight: 5}} onClick={() => {this.setState({archivedVisible: false})}} ><EyeOutlined/> Archivées</Button>
                )
              }
              <Input style={{width: 300, marginBottom: 10}} placeholder='Rechercher (Nom, montant, numéro, dernière action)' onChange={(e) => this.setState({searchValue: e.target.value})}/>
            </div>
          </div>
          <Table
            size={'small'}
            bordered={true}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
        </div>
      </>
    )
  }
}

export default withRouter(connect(
  state => ({
    userId: state.user.info.id,
    token: state.user.token,
    invoices: state.invoices.invoices,
    isModalVisible: state.invoices.isModalOpen,
    invoicesPdf: state.invoicesPdf,
    companies : state.clients.clients
  }),
  {
    fetchUser: userActions.fetchUser,
    confirmUser: userActions.confirmUser,
    fetchInvoices: invoiceActions.fetchInvoices,
    updateInvoices : invoiceActions.updateInvoices,
    bulkArchiveInvoice: invoiceActions.bulkArchiveInvoice,
    exportToExcel : invoiceActions.exportToExcel,
    updateDefaultFormValues: calculatorActions.updateDefaultFormValues,
    removePDFRequest: invoicePdfActions.removePDFRequest,
    uploadPDFRequest: invoicePdfActions.uploadPDFRequest,
    isModalOpen : invoiceActions.isModalOpen,
    bulkAddInvoice: invoiceActions.bulkAddInvoice,
    fetchClients : clientsActions.fetchClients,
  }
)(CustomerInvoices));
